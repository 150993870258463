import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(({ color }) => ({
  link: {
    color: color.linkColor,
  },
}));

export type SimpleLinkProps = {
  href: string;
  openInNewTab?: boolean;
  children: string;
};

export const SimpleLink = ({
  href,
  openInNewTab = false,
  children,
}: SimpleLinkProps) => {
  const classes = useStyles();

  return (
    <a
      href={href}
      className={classes.link}
      target={openInNewTab ? "_blank" : undefined}
    >
      {children}
    </a>
  );
};

import { asPartialTheme } from "@theme/types";

import { commonTheme } from "./common";

const colorPalette = {
  white: "#FFFFFF",
  primary: "#FBF3F0",
  secondary: "#F0D1D7", // ligthRed, 240, 209, 215, infocard bg
  greyCoal: "#585858",
  warning: "#FF1C1C",
  error: "#E31717",
  gray3: "#FBF3F0",
  gray1: "#F4F4F4",
  gray2: "#E2E0DD",
  gray5: "#444349",
  black: "#101010",
  green: "#00A744", // green, 0, 167, 167
  gray8: "#CCC9BF", // 204, 201, 191 = 596
  gray7: "#CAC5BF", // 202, 197, 191 = 590
  gray11: "#B9B6B6", // 185, 182, 182 = 549
  gray10: "#4F4F4F", // 79, 79, 79 = 237
  gray6: "#2D2D2D", // 45, 45, 45 = 135
  gray9: "#272727", // 39, 39, 39 = 117
};

export const commonDarkTheme = asPartialTheme({
  ...commonTheme,
  color: {
    primary: "#FBF3F0",
    mediumGray: "#E2E0DD",
    black: "#2D2D2D",
    defaultText: "#C2C2C2",
    error: "#C83C42",
    qrButtonColor: colorPalette.white,
    // topNavBar
    topNavBarText: "#C2C2C2",
    topNavBarBg: "#101010",
    topNavBarTableText: "#E9E9E9",
    topNavBarTableBg: "rgba(255, 255, 255, 0.2);",
    topNavBarBgImgTopGradient:
      "linear-gradient(180deg, #101010 0%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 100%)",
    topNavBarBgImgBottomGradient:
      "linear-gradient(0deg, #101010 0%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 100%)",
    // topCategories
    topCategoriesHeadingText: "#BDBDBD",
    topCategoriesBg: "#101010",
    topCategoriesActiveText: "#F2F2F2",
    topCategoriesMenuButtonBg: "#010101",
    topCategoriesMenuButtonText: "#FFFFFF",
    // horizontalScrollable
    horizontalScrollableButtonBg: "#101010",
    // subCategories
    subCategoriesHeadingText: "#BDBDBD",
    subCategoriesText: "#C2C2C2",
    subCategoriesTextActive: "#F2F2F2",
    subCategoriesBg: "#101010",
    // productRow
    productRowTitle: "#C2C2C2",
    productRowPrice: "#C2C2C2",
    productRowDescription: "#C2C2C2",
    productRowBg: "#1E1E1E",
    productRowAmountBoxShadow: "0px 0px 8px rgba(255, 255, 255, 0.3)",
    productRowAmountBg: "#101010",
    productRowFeaturedBg: "#C83C42",
    productRowFeaturedText: "#FFFFFF",
    // amount button
    amountBtnBorder: "#989898",
    amountBtnBg: "#101010",
    amountBtnReversedBg: "#101010",
    amountBtnReversedText: "#F2F2F2",
    // burgerMenu
    burgerMenuBg: "#2D2D2D",
    burgerMenuBorders: "#1E1E1E",
    burgerMenuText: "#E9E9E9",
    burgerMenuUserNameText: "#101010",
    burgerMenuUserStatusText: "#444349",
    burgerMenuIcons: "#C2C2C2",
    burgerMenuDropdownIcon: "#E9E9E9",
    burgerMenuDropdownIconDisabled: "#E1E1E1",
    burgerMenuLoginButtonBg: "#2D2D2D",
    burgerMenuLoginButtonText: "#989898",
    burgerMenuLogoutButtonBg: colorPalette.white,
    burgerMenuLogoutButtonText: "#2D2D2D",
    burgerMenuLogoutButtonBorder: "#CAC5BF",
    burgerMenuItemTop: "#1E1E1E",
    burgerMenuBoxShadow: "0px -2px 0px rgba(0, 0, 0, 0.2)",
    burgerMenuItemLabel: "#E9E9E9",
    burgerMenuAccountLogIn: "#C2C2C2",
    burgerMenuBtnBg: colorPalette.black,
    burgerMenuBtnText: "#989898",
    burgerMenuOutlineBtnBorder: "#989898",
    // modals
    modalBg: "#1E1E1E",
    modalText: "#444349",
    modalQrHelpText: "#C2C2C2",
    modalQrText: "#C2C2C2",
    modalQrButtonBg: "#1E1E1E",
    modalQrButtonBorder: "#989898",
    modalQrButtonIcon: "#989898",
    modalQrScannerBg: "#FBF3F0",
    modalQrScannerText: "#444349",
    modalQrScannerIcon: "#989898",
    modalQrScannerError: "#E31717",
    modalQrScannerOuterContainerBg: "#1E1E1E",
    modalQrScannerPreviewBg: "#1E1E1E",
    modalQrScannerCloseBtnBg: "rgba(255, 255, 255, 0.5)",
    modalQrScannerPreviewContentText: "#C2C2C2",
    modalQrScannerPreviewContentPText: "#C2C2C2",
    modalQRScannerLink: "#C2C2C2",
    modalQrTableNrBg: "#1E1E1E",
    modalLoginBg: "#FBF3F0",
    modalLoginText: "#444349",
    modalLoginTitle: "#101010",
    modalLoginHintText: "#444349",
    modalLoginErrorBg: "rgba(242, 129, 25, 0.24)",
    modalLoginErrorText: "#444349",
    modalDeleteBg: "#1E1E1E",
    modalDeleteText: "#C2C2C2",
    modalSearchBg: "#101010",
    // fields
    fieldBg: "#1E1E1E",
    fieldBorder: "#585858",
    fieldBorderFocused: "#c8c8c8",
    fieldErrorBorder: "#E31717",
    fieldText: "#C2C2C2",
    fieldTextPlaceholder: colorPalette.gray11,
    fieldIcon: "#FBF3F0",
    fieldIconBg: "#444349",
    fieldLabelText: "#989898",
    // productPage
    productPageBoxTitle: "#C2C2C2",
    productPageBoxPrice: "#989898",
    productPageBoxDescription: "#C2C2C2",
    productPageHeadingText: "#E9E9E9",
    productPageDescriptionText: "#E9E9E9",
    productPageImageTitle: "#C2C2C2",
    productPageMandatoryOption: "#828282",
    productPageShapeBlockBorder: "#C2C2C2",
    productPageShapeBlockLabel: "#C2C2C2",
    productPageShapeBlockActiveBg: "#585858",
    productPageShapeBlockActiveText: "#F2F2F2",
    // buttons
    buttonText: "#101010",
    buttonBg: "#989898",
    buttonTransparentBg: "#1E1E1E",
    buttonTransparentText: "#989898",
    buttonTransparentBorder: "#989898",
    disabledButtonBg: "#585858",
    disabledButtonText: "#101010",
    requestPermissionBtnBg: "#1E1E1E",
    // productAddSuccess
    productAddSuccessBg: "#1E1E1E",
    productAddSuccessText: "#C2C2C2",
    // contactInfoModal+
    contactInfoModalLabel: colorPalette.black,
    contactInfoModalFooterBg: colorPalette.white,
    contactInfoMessageLabel: "#989898",
    contactInfoTitle: "#E9E9E9",
    white: colorPalette.white,
    greyCoal: "#585858",
    // modals+
    modalOverlay: "rgba(0,0,0,0.4)",
    // loginSuccessModal
    loginSuccessModalBg: colorPalette.white,
    loginSuccessModalText: colorPalette.gray6,
    loginSuccessModalIcon: colorPalette.green,
    fieldIconGreen: colorPalette.green,
    fieldCountryListText: "#C2C2C2",
    // productPage+
    productPageSpecialNeedsFieldBg: "#1E1E1E",
    productPageSpecialNeedsFieldBorder: "#585858",
    productPageSpecialNeedsComment: "#C2C2C2",
    productPageSpecialNeedsTitle: "#E9E9E9",
    // cartPage+
    cartPageRowTitle: "#C2C2C2",
    cartPageRowPrice: "#C2C2C2",
    cartPageRowTrashIcon: "rgba(0, 0, 0, 0.24)",
    cartPageItemBlockBorder: "#2D2D2D",
    cartPageConfirmBlockBg: "#101010",
    cartPageConfirmBlockText: colorPalette.white,
    cartPageItemsTitle: "#C2C2C2",
    cartPageTotalBlock: "#C2C2C2",
    // qttBlock+
    qttText: "#F2F2F2",
    qttActiveIcon: "#E9E9E9",
    qttActiveReversedIcon: "#E9E9E9",
    qttInactiveIcon: "#585858",
    qttInactiveReversedIcon: "#585858",
    // orderStatusPage+
    orderStatusPageText: "#C2C2C2",
    orderStatusPageRowText: "#C2C2C2",
    orderStatusPageLineText: "#989898",
    orderStatusPageOrderNr: "#C2C2C2",
    orderStatusPageEmail: "#C2C2C2",
    orderStatusPageExtraSalesTitle: "#E9E9E9",
    orderStatusPagePickUpText: "#989898",
    orderStatusPageLocationText: "#C2C2C2",
    // loadingScreen+
    loadingScreenBg: "rgba(0,0,0,0.4)",
    // warning
    warningBg: "#C83C42",
    // sign-in block
    signInBackground:
      "linear-gradient(129.21deg, #FF8002 5.17%, #F2BA4C 94.4%)",
    signInBlockBg: "#585858",
    signedInBlockBg: "#101010",
    signedInBlockH5: "#C2C2C2",
    signedInBlockH4: "#F2F2F2",
    signedInBlockSwitchAccount: "#F2F2F2",
    signedInBlockSwitchBtn: "#F2F2F2",
    signedInBlockBorder: "#101010",
    // menu block
    menuBlockTitle: "#C2C2C2",
    menuBlockSearchBg: "#585858",
    menuBlockSearch: "#C2C2C2",
    menuBlockArrowBg: "#101010",
    menuBlockSearchIcon: "#C2C2C2",
    // search menu
    searchMenuDot: "#C2C2C2",
    searchCategoriesText: "#F2F2F2",
    searchMenuCloseIcon: "#101010",
    // box component
    boxShadowTop: "0px -8px 10px rgba(0, 0, 0, 0.025)",
    boxShadowGlobal: "0px 0px 12px 2px rgba(0, 0, 0, 0.05)",
    boxListBg: "#101010",
    boxBg: "#1E1E1E",
    // footer
    footerBg: "#101010",
    footerBoxShadow: "0px -8px 10px rgba(0, 0, 0, 0.025)",
    footerMenuBtnStroke: "#FFFFFF",
    footerCartIconActive: "#101010",
    footerCartIconInactive: "#101010",
    // brand
    brandLocationTitle: "#E0E0E0",
    // error page
    errorPageTitle: "#F2F2F2",
    errorPageText: "#F2F2F2",
    // payment failed
    paymentFailedText: "#F2F2F2",
    checkoutCancelBtnBg: "transparent",
    checkoutCancelBtn: "#F2F2F2",
    // location info modal
    locationInfoHeader: "#E9E9E9",
    locationText: "#E9E9E9",
    locationDescription: "#E9E9E9",
    // language dropdown
    languageDropdownBg: "rgba(45, 45, 45, 0.30)",
    languageDropdownButtonBg: "rgba(104, 104, 104, 0.80)",
    languageDropdownButtonText: "#989898",
    languageDropdownSelectedButtonText: "#F2F2F2",
    languageDropdownButtonHoverText: "#F2F2F2",
    languageDropdownButtonHoverBg: "rgba(104, 104, 104, 0.80)",
    languageDropdownSelectedLanguageBg: "rgba(104, 104, 104, 0.80)",
    //location box
    locationBoxBg:
      "linear-gradient(90.59deg, #2D2D2D 0.51%, rgba(45, 45, 45, 0.692708) 16.19%, rgba(45, 45, 45, 0) 31.87%);",
    locationBoxNavigation: "#C83C42",
    locationBoxHeader: "#C2C2C2",
    locationBoxText: "#989898",
    //navbar
    navbarLoginButtonBg: "#989898",
    navbarLoginButtonText: "#101010",
    navbarDiningMethodButtonBg: "rgba(16,16,16,0.5)",
    navbarDiningMethodButtonText: "#FFFFFF",
    navbarLoyaltyClub: "#E9E9E9",
    navbarCurrentLanguage: "#E9E9E9",
    navbarUser: "#E9E9E9",
    // banner
    bannerSuccessBackground: "#618152",
    bannerErrorBackground: "#FFEAE5",
    bannerSuccessTextColor: "#C2C2C2",
    bannerErrorTextColor: "#C2C2C2",
    // Status Pag
    orderStatusPageBg: "#101010",
    // order status card
    orderStatusCardBackground: colorPalette.gray6,
    orderStatusCardBackgroundHighlight: "#618152",
    orderStatusCardShadow: "0px 0px 12px 2px rgba(0, 0, 0, 0.04)",
    orderStatusCardInactiveBackgroundColor: "#2D2D2D",
    orderStatusCardPendingBackgroundColor: "#703014",
    orderStatusCardPaidBackgroundColor: "#0A450C",
    orderStatusCardInfoLabelTextColor: "#E9E9E9",
    orderStatusCardInfoValueTextColor: "#BDBDBD",
    orderStatusCardStatusRowTimeTextColor: "#C2C2C2",
    orderStatusCardPreOrderTitleTextColor: "#C2C2C2",
    orderStatusCardQueueNumberLabelTextColor: "#C2C2C2",
    orderStatusCardQueueNumberValueTextColor: "#C2C2C2",
    orderStatusCardOrderCardCompletedBlockTitleTextColor: "#E9E9E9",
    orderStatusCardOrderCardCompletedBlockSubtitleTextColor: "#BDBDBD",
    // cart section
    cartSectionButtonContainerBackground: "#1E1E1E",
    cartSectionButtonContainerShadow: "0px 0px 3px 1px rgba(0,0,0,0.08)",
    //landing image
    landingImageBg: "#101010",
    //keyValueList
    lineKey: "#C2C2C2",
    lineValue: "#C2C2C2",
    // toggle
    toggleBackground: "#101010",
    toggleBorderColor: "#585858",
    toggleOptionColor: "#585858",
    toggleHoveredOptionColor: "#C2C2C2",
    toggleSelectedOptionColor: "#C2C2C2",
    toggleSelectedOptionBackground: "#EC213C",
    // dining option modal
    qrScannerButtonBackground: "#989898",
    infoTextColor: "#989898",
    // skeleton
    skeletonElementBg: colorPalette.gray6,
    skeletonElementShimmer:
      "linear-gradient(70deg, rgba(255,255,255,0) 20%, rgba(0,0,0,0.15) 49%, rgba(52,52,52,0) 80%)",
    // Retry component
    retryHintTextColor: "#C2C2C2",
    // Link component
    linkColor: "#FBF3F0",
    // Loader component
    loaderSpinnerColor: "#F68A1F",
    // ImageCarousel component
    imageCarouselDotColor: "#F68A1F",
    imageCarouselDotSelectedColor: "#ffa500",
    imageCarouselDotSelectedProgressColor: "#F68A1F",
  },
  blobStorage: {
    // Warning icon
    warningIcon:
      "https://assets.markus.live/00-generic-app/icons/generic-icons/Caution_Small_thin.svg",
    // Error Page bgImage
    errorPageBg: "https://assets.markus.live/03-order-app/dark/media/ERROR.svg",
    // Open Burger Menu - Bottom of the Menu
    markusLogo:
      "https://assets.markus.live/00-generic-app/logo/markus_footer_lightgray-color_130x46.svg",
    markusLogoBlack:
      "https://assets.markus.live/00-generic-app/logo/markus_footer_lightgray-color_130x46.svg",
    // Quantity minus icon on Product Page / Cart Page; Active - can be changed (clicked)
    amountMinusActiveIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Remove_thin.svg",
    // Quantity plus icon on Product Page / Cart Page; Active - can be changed (clicked)
    amountPlusActiveIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Add_thin.svg",
    // When Open Modals and Pages that act like a modal - Close button appear in top right corner
    closeModalIcon:
      "https://assets.markus.live/03-order-app/dark/media/close.svg",
    // Icon to open burger menu
    burgerIcon:
      "https://assets.markus.live/03-order-app/dark/icons/topbar/TopBar_Hamburger_thin_default.svg",
    // Icon to open burger menu, when having notification (red dot on it appears)
    burgerActiveIcon:
      "https://assets.markus.live/03-order-app/dark/icons/topbar/TopBar_Hamburger_thin_active.svg",
    // Cart Icon, appears in burger menu for My Orders item
    cartIcon:
      "https://assets.markus.live/00-generic-app/icons/generic-icons/Shopping_Cart.svg",
    // QR icon that appears inside a text field, when you open QR code manual entry modal
    qrIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Scan_QR_thin.svg",
    //  "https://assets.markus.live/00-generic-app/icons/generic-icons/Scan_QR_thin.svg",
    // Appears in 2 places: Table Number block (located in the right side) on homepage, restaurant info page, cart page; and inside QR manual entry modal for one of the buttons
    scanQrIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Scan_QR_thin.svg",
    // Icon that indicates that field validation passed, located in login form - email field; if email is in valid format - it will display it
    validFieldIcon:
      "https://assets.markus.live/03-order-app/dark/media/valid_field_green.svg",
    // Displayed on Product page. Product must have modifier.Choices in database
    extraChoiceIcon:
      "https://assets.markus.live/00-generic-app/icons/generic-icons/Checker_checked_yes_checked-filled_yes.svg",
    orderPendingIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Timing_thin.svg",
    // Status in progress icon
    statusInProgressIcon:
      "https://assets.markus.live/03-order-app/dark/media/Checker_progress.svg",
    // Status done icon
    statusReadyIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Checker_checked_yes_checked-filled_yes_dark-yes.svg",
    // Status not done icon
    statusNotReadyIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Checker_active-yes_selected-no_dark-yes.svg",
    // Displayed when trying to log in and API returned an error (cannot log in)
    failIcon: "https://assets.markus.live/03-order-app/dark/media/fail.svg",
    // Confirm Age Button on Cart Page - disabled state (unchecked)
    checkedWhiteBgSmIcon:
      "https://assets.markus.live/00-generic-app/icons/generic-icons/Checker_checked_yes_checked-filled_no.svg",
    // Displayed in Burger menu for finished order when KDS system is disabled, in Burger Menu - language selection (current active langage), on order status page when step is completed, in product extras block on product page, in product shape (size) block on product page
    checkedRadioSmIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Checker_checked-yes_radio_dark-yes.svg",
    // Displayed in Burger Menu - language selection (inactive langage), on order status page when step is incompleted, in product extras block on product page, in product shape (size) block on product page
    emptyRadioSmIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Checker_active-yes_selected-no_dark-yes.svg",
    // Displayed on Login Success Modal (when you logged in successfully), when you added item to the cart
    successGrayIcon:
      "https://assets.markus.live/03-order-app/dark/media/Checker_success.svg",
    // Displayed in Restaurant Info Block on homepage, and in Resturant Info Page
    locationIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Location.svg",
    schedulingIcon:
      "https://assets.markus.live/00-generic-app/icons/generic-icons/Scheduling_thin.svg",
    timeIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Time_thin.svg",
    // Displayed for warning block, when: login form has error, when age verification is required on product page and cart page
    triangleIconActive:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Caution_Small_active.svg",
    // Burger Menu - Language menu block
    warningUnchecked:
      "https://assets.markus.live/00-generic-app/icons/generic-icons/Checker_Alert-yes.svg",
    warningChecked:
      "https://assets.markus.live/00-generic-app/icons/generic-icons/Checker_checked_yes_checked-filled_no.svg",
    caretRightThinIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Chevron_Forward_thin.svg",
    caretLeftThinIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Chevron_Back_thin.svg",
    caretDownThinIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Expand_thin.svg",
    caretUpThinIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Collapse_thin.svg",
    // In burger Menu - each item has arrow up / down - that's the icon, icon gets rotated by code
    dropMenuIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Expand.svg",
    // When you press on search button on homepage - it opens search modal - there will be input field where this icon is appeared inside of it
    magnifyingGlassIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Search_Small.svg",
    // Password input field - show password icon (displayed when password is invisible to user)
    showIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Show_Preview_thin.svg",
    // Password input field - hide password icon (displayed when password is visible to user)
    hideIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Hide_Preview_thin.svg",
    // trash icon is used on cart page - for item row and for the delete all items in the header
    trashIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Delete_thin.svg",
    // In burger menu - logout button has the logout icon
    logoutIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Sign_Out_thin.svg",
    // Displayed on Cart page - user block, the left icon that looks like card
    smallLoyaltyClubCardIcon:
      "https://assets.markus.live/00-generic-app/media/ApolloClubCardSmall_74x48.svg",
    tinyLoyaltyClubCardIcon:
      "https://assets.markus.live/00-generic-app/media/ApolloClubCardSmall_62x40.svg",
    rotatedLoyaltyClubCardIcon:
      "https://assets.markus.live/01-loyalty-portal/media/ApolloClubCard_big_angled.svg",
    plusIcon:
      "https://assets.markus.live/00-generic-app/icons/generic-icons/Add_thin.svg",
    closeIcon:
      "https://assets.markus.live/03-order-app/light/icons/generic-icons/Close_Big_thin.svg",
    commentIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/small/Comments_thin.svg",
    cameraSwitchIcon:
      "https://assets.markus.live/00-generic-app/icons/generic-icons/Switch_Camera_thin.svg",
    languageIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Language_Change_2_thin.svg",
    locationMap:
      "https://assets.markus.live/03-order-app/assets-%tenantKey%-%tenantLocation%/dark/maps/map_%tenantKey%_%tenantLocation%.png",
    settingsIcon:
      "https://assets.markus.live/03-order-app/dark/icons/generic-icons/Settings.svg",
    statusBannerImage:
      "https://assets.markus.live/03-order-app/assets-%tenantKey%-%tenantLocation%/dark/media/SMS.webp",
    diningOptionIconDineIn:
      "https://assets.markus.live/03-order-app/dark/media/Dinein_big.svg",
    diningOptionIconTakeaway:
      "https://assets.markus.live/03-order-app/dark/media/Takeaway_big.svg",
    spinnerIconSmall:
      "https://assets.markus.live/03-order-app/dark/media/loader_small_24.svg",
    exitPageIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Exit_Page_Small_thin.svg",
    copyIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Copy_Small.svg",
    checkerWithBorderIcon:
      "https://assets.markus.live/00-generic-app/icons-dark-theme/generic-icons/Checker_checked_yes_checked-filled_no.svg",
    burgerMenuLinks: [],
  },
  scrollbar: {
    height: "8px",
    width: "8px",
    borderRadius: "4px",
    backgroundColor: "#101010",
    thumb: {
      borderRadius: "4px",
      backgroundColor: "#515151",
      hover: {
        backgroundColor: "#75787a",
      },
    },
  },
} as const);

import config from "@constants/config";
import { TOptions } from "i18next";
import { useTranslation } from "react-i18next";

import {
  FilterNamespaceKeys,
  UseSalesPointTranslationKeyResults,
  ValidNamespaceKeys,
} from "./useSalesPointTranslationKey.types";

const useSalesPointTranslationKey = (): UseSalesPointTranslationKeyResults => {
  const { i18n } = useTranslation();

  const resolveKey = <
    TKey extends ValidNamespaceKeys<TOpt>,
    TOpt extends TOptions,
  >(
    key: TKey,
    _tOptions?: TOpt,
  ): FilterNamespaceKeys<TKey, TOpt>[] => {
    if (
      i18n.exists(
        `${config.tenantConfig.key}.${config.tenantConfig.location}.${String(key)}`,
        { ns: "salespoint" },
      )
    ) {
      return [
        `${config.tenantConfig.key}.${config.tenantConfig.location}.${String(key)}`,
      ] as FilterNamespaceKeys<TKey, TOpt>[];
    }

    if (
      i18n.exists(`${config.tenantConfig.key}.default.${String(key)}`, {
        ns: "salespoint",
      })
    ) {
      return [
        `${config.tenantConfig.key}.default.${String(key)}`,
      ] as FilterNamespaceKeys<TKey, TOpt>[];
    }

    if (i18n.exists(`default.${String(key)}`, { ns: "salespoint" })) {
      return [`default.${String(key)}`] as FilterNamespaceKeys<TKey, TOpt>[];
    }

    return [
      `${config.tenantConfig.key}.${config.tenantConfig.location}.${String(key)}`,
    ] as FilterNamespaceKeys<TKey, TOpt>[];
  };

  return {
    resolveKey,
  };
};

export default useSalesPointTranslationKey;
